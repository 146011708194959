import * as React from 'react';
import { Level, LevelState, LevelFeedback } from './level';

const level8: Level = {
  columns: 3,
  rows: 3,
  gap: "2em",
  elements: [
    {
      position: {
        column: 0,
        row: 0,
      },
      id: "weatherStation",
      icon: "weatherStation",
    },
    {
      position: {
        column: 0,
        row: 1,
      },
      id: "kinesis",
      droppable: true,
    },
    {
      position: {
        column: 0,
        row: 2,
      },
      id: "dynamodb",
      droppable: true,
    },
    {
      position: {
        column: 1,
        row: 1,
      },
      id: "lambda",
      droppable: true,
    },
    {
      position: {
        column: 2,
        row: 1,
      },
      id: "ses",
      droppable: true,
    },
    {
      position: {
        column: 2,
        row: 2,
      },
      id: "users",
      icon: "users3"
    },
  ],
  relations: [
    {
      sourceId: "weatherStation",
      targetId: "kinesis",
      sourceAnchor: "bottom",
      targetAnchor: "top",
    },
    {
      sourceId: "kinesis",
      targetId: "dynamodb",
      sourceAnchor: "bottom",
      targetAnchor: "top",
      dashed: true,
      doubleArrow: true,
    },
    {
      sourceId: "kinesis",
      targetId: "lambda",
      sourceAnchor: "right",
      targetAnchor: "left",
    },
    {
      sourceId: "lambda",
      targetId: "ses",
      sourceAnchor: "right",
      targetAnchor: "left",
    },
    {
      sourceId: "ses",
      targetId: "users",
      sourceAnchor: "bottom",
      targetAnchor: "top",
    },
  ],
  awspalette: ["s3", "dynamodb", "ses", "sns", "lambdaTensorflow", "kinesis"],
  validator: Level8Validator,
};

function Level8Validator(state: LevelState): LevelFeedback {

  // needs to be correct
  if( !(state.kinesis === "kinesis") )
    return { correct: false, feedbackComponent: "Die Wetterdaten werden nicht empfangen." };
  if( !(state.dynamodb === "s3" || state.dynamodb === "dynamodb"))
    return { correct: false, feedbackComponent: "Die Wetterdaten können nicht abgespeichert werden." };
  if( !(state.lambda === "lambdaTensorflow") )
    return { correct: false, feedbackComponent: "Die gesamelten Daten werden nicht richtig verarbeitet" };
  if( !(state.ses === "ses" || state.ses === "sns") )
    return { correct: false, feedbackComponent: "Es werden keine Benachrichtigungen verschickt." };

  // possible:
  let stars = 3;
  let message = "";
  if (state.dynamodb === "s3") {
    stars--;
    message += "Zu viele kleine Daten für S3. ";
  }
  if (state.ses === "sns") {
    stars--;
    message += "Es gibt ein extra AWS Service für das Versenden von E-Mails. ";
  }
  return {correct: true, stars: stars, feedbackComponent: message};
}

export default level8;
