import level1 from 'levels/level1';
import level2 from 'levels/level2';
import level3 from 'levels/level3';
import level4 from 'levels/level4';
import level5 from 'levels/level5';
import level6 from 'levels/level6';
import level7 from 'levels/level7';
import level8 from 'levels/level8';
import level9 from 'levels/level9';
import level10 from 'levels/level10';

export const LEVEL_TITLES = [
    'Einführung',
    'Fotostudio',
    'Lambda Functions',
    'Spielserver',
    'Aktienkurse',
    'E-Mail-Empfang',
    'Benachrichtigungen',
    'Tornado-Warnsystem',
    'Machine Learning Wetterdaten',
    'Internet of Things (IoT)',
];

export const LEVELS = [
    level1,
    level2,
    level3,
    level4,
    level5,
    level6,
    level7,
    level8,
    level9,
    level10
];
