export interface Source {
  name: string;
  creator: string;
  url: string;
}

const sources: Source[] = [
  {
    "name": "browser",
    "creator": "Smashicons",
    "url": "https://www.flaticon.com/free-icon/web_1034609",
  },
  {
    "name": "camera",
    "creator": "Smashicons",
    "url": "https://www.flaticon.com/free-icon/photo-camera_149098",
  },
  {
    "name": "customer",
    "creator": "Freepik",
    "url": "https://www.flaticon.com/free-icon/employee_912316",
  },
  {
    "name": "documents",
    "creator": "Smashicons",
    "url": "https://www.flaticon.com/free-icon/folder_149333",
  },
  {
    "name": "shop",
    "creator": "Freepik",
    "url": "https://www.flaticon.com/free-icon/shop_1067602",
  },
  {
    "name": "supportEmployee",
    "creator": "Good Ware",
    "url": "https://www.flaticon.com/free-icon/support_771601",
  },
  {
    "name": "weatherStation",
    "creator": "Freepik",
    "url": "https://www.flaticon.com/free-icon/wind-sign_1851004",
  },
  {
    "name": "webServer",
    "creator": "Freepik",
    "url": "https://www.flaticon.com/free-icon/globe_1034591",
  },
  {
    "name": "weatherData",
    "creator": "Pause08",
    "url": "https://www.flaticon.com/free-icon/cloud_860276",
  },
  {
    "name": "cactus",
    "creator": "Freepik",
    "url": "https://www.flaticon.com/free-icon/cactus_875030",
  },
  {
    "name": "waterTap",
    "creator": "Freepik",
    "url": "https://www.flaticon.com/free-icon/water-tap_1890929",
  },
];

export default sources;

